@import '../fonts';

/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 14px;
  }

  a {
    text-decoration: underline;
  }
  a:hover {
    opacity: 0.6;
  }

  .link--reset {
    text-decoration: none;
  }
  .link--reset:hover {
    opacity: 1;
  }

  .link--invisible {
    color: theme('colors.current');
    text-decoration: none;
    cursor: auto;
  }

  .link--inverted {
    text-decoration: none;
  }
  .link--inverted:hover {
    text-decoration: underline;
    opacity: 1;
  }

  strong {
    font-weight: 600;
  }
}

.markdown-content h2 {
  text-transform: uppercase;
}
.markdown-content h2::before {
  content: '¶';
  margin-right: theme('spacing.1');
}

.markdown-content h3 {
  font-style: italic;
}

.markdown-content ul {
  list-style: inside;
  margin-left: theme('spacing[0.5]');
}
